@import url("https://fonts.googleapis.com/css2?family=Karla:wght@300&display=swap");

* {
   margin: 0;
   box-sizing: border-box;
}

body {
   font-family: "Karla", sans-serif;
   font-size: 16px;
   background-image: url("./img/bg-image.png");
   height: 900px;
}

.logo {
   width: 100px;
   margin-top: 0.25rem;
   margin-left: 4rem;
}

.header {
   display: flex;
   justify-content: space-between;
   background-color: #36a4b5;
   color: aliceblue;
   text-transform: uppercase;
   font-weight: 800;
}

.nav-items > ul {
   list-style-type: none;
   display: flex;
   margin: 2rem 2rem;
   letter-spacing: 1px;
}

.nav-items > ul > li {
   padding: 1rem;
}

.animate-charcter {
   text-transform: uppercase;
   text-align: center;
   background-image: linear-gradient(
      -225deg,
      #231557 0%,
      #44107a 29%,
      #ff1361 67%,
      #0026ff 100%
   );
   background-size: auto auto;
   background-clip: border-box;
   background-size: 200% auto;
   color: #fff;
   background-clip: text;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   animation: textclip 2s linear infinite;
   display: inline-block;
   font-size: 100px;
}

@keyframes textclip {
   to {
      background-position: 200% center;
   }
}

.basic-content {
   margin-top: 3rem;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 700px;
}
h1 {
   margin: 3rem;
}
h2 {
   margin: 2rem;
   color: aliceblue;
   background-color: #36a4b5;
   padding: 2rem;
   letter-spacing: 0.3rem;
   border-radius: 1rem;
   font-weight: 600;
}

h3 {
   margin: 2rem;
   color: aliceblue;
   background-color: #36a4b5;
   padding: 2rem;
   letter-spacing: 0.3rem;
   border-radius: 1rem;
   text-transform: uppercase;
}

.footer {
   color: aliceblue;
   margin-top: 2rem;
   padding: 1rem;
   background-color: #36a4b5;
   text-align: center;
   text-transform: uppercase;
   letter-spacing: 0.2rem;
}
